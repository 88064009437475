import React, { useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import Mixer from './Mixer'
const options={
  sons:[
    {
      url:'/sons/message2.wav',
      listeners:[
        { hook:'hook1', track:'track-4' },
        { hook:'hook2', track:'track-5' },
      ]
    },
    {
      url:'/sons/message2.mp3',
      listeners:[
        { hook:'hook1', track:'track-4' },
        { hook:'hook2', track:'track-5' },
      ]
    },
  ]
}
function App() {
  const mixer=useRef(new Mixer(options));
  const initSound=()=>{
    if (!mixer.current.inited) mixer.current.init();
  };
  const play=(idx)=>{
    setTimeout(()=>mixer.current.play(idx),10);
  }
  const playOut=(idx)=>{
    setTimeout(()=>mixer.current.playOut(idx),10);
  }
  const stop=(idx)=>{
    mixer.current.stop(idx);
  }
  const fadeIn=(idx)=>{
    setTimeout(()=>mixer.current.fadeIn(idx),10);
  }
  const fadeOut=(idx)=>{
    setTimeout(()=>mixer.current.fadeOut(idx),10);
  }
  return (
    <div className="App" onClick={initSound}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.<br />
          Build Time:
        </p>
        {options.sons.map((son,i)=><div key={son.url}>
          <button onClick={()=>play(son.url)}>Play {son.url}</button>
          <button onClick={()=>playOut(son.url)}>PlayOut {son.url}</button>
          <button onClick={()=>stop(son.url)}>Stop {son.url}</button>
          <button onClick={()=>fadeIn(son.url)}>fadeIn {son.url}</button>
          <button onClick={()=>fadeOut(son.url)}>fadeOut {son.url}</button>
        </div>)}
        </header>
    </div>
  );
}

export default App;
