const fadeDuration=1000;
const step=10;
class Player {
  constructor ({src,audioContext,master,track,trackVolume,volume}) {
    this.context=audioContext;
    this.master=master;
    this.audio=new Audio();
    this.audio.addEventListener('ended',this.clear.bind(this));
    this.src=src;
    ['suspend','abort','emptied','stalled','error','loadstart','durationchange','loadedmetadata','loadeddata','progress','canplay','canplaythrough'].forEach((eventName) => {
      this.audio.addEventListener(eventName,(e,detail)=>console.log(eventName,src,e,detail));
    });
    this._volume=1;
    this._onend=[];
    this._onfade=[];
    this._onload=[];
    this._onstop=[];
    this._onplay=[];
    this._onpause=[];
    this.fadeInterval=null;
    this.track = track;
    this.trackVolume = trackVolume;
    this.trackVolume.connect(this.master);
    this.playerGain = this.context.createGain();
    this.playerGain.connect(this.trackVolume);
    this.playerGain.gain.value=volume;
    this.gainNode = this.context.createGain();
    this.gainNode.connect(this.playerGain);
    this.gainNode.gain.value=this._volume;
    this.source=this.context.createMediaElementSource(this.audio);
    this.source.connect(this.gainNode);
  }
  get volume() {
    return this._volume;
  }
  set volume(v) {
    this._volume = v;
    this.gainNode.gain.setValueAtTime(1, this.context.currentTime);
  }
  init(cb){
    const audio=this.audio;
    const handler=()=>{
      console.log('canplay');
      audio.removeEventListener('canplay',handler);
      cb();
    }
    this.audio.addEventListener('canplay',handler);
    this.audio.src=this.src;
    this.audio.load();
    console.log('init',this.src);
  }
  clear(){
    this.audio.removeAttribute('src');
    console.log('clear',this.src);
  }
  fade(v,duration) {
    this.gainNode.gain.cancelScheduledValues(this.context.currentTime);
    //this.gainNode.gain.linearRampToValueAtTime(this._volume, this.context.currentTime + 1);
    //console.log('fade volume',this.gainNode.gain.value);
    this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime);
    this.gainNode.gain.linearRampToValueAtTime(v, this.context.currentTime + duration/1000);
  }

  play() {
    console.log('play request',this.src);
    const doPlay=()=>{
      this.gainNode.gain.setValueAtTime(1, this.context.currentTime);
      this.audio.play().then(()=>{
      }).catch((e)=>console.log(e));
    }
    if(!this.audio.src) this.init(doPlay.bind(this));
    else doPlay();
  }
  playOut() {
    const tmp=new Audio(this.src);
    const source=this.context.createMediaElementSource(tmp);
    const handler=()=>{
      console.log('end')
      tmp.removeAttribute('src');
      source.disconnect();
      tmp.removeEventListener('ended',handler);
    }
    tmp.addEventListener('ended',handler);
    source.connect(this.gainNode);
    tmp.play();
  }
  stop() {
    if(this.audio.src) {
      this.fade(0,fadeDuration);
      this.stopTimeout=setTimeout(()=>{
        this.audio.pause();
        this.audio.currentTime=0;
        this.clear();
      },fadeDuration);
    }
  }
  pause() {
    this.audio.pause();
  }
  on(event, fn, once) {
    var events = this['_on' + event];
    if (typeof fn === 'function') {
      events.push(once ? {fn: fn, once: once} : {fn: fn});
    }
    return this;
  }
  off(event, fn, id) {
    var events = this['_on' + event];
    var i = 0;
    if (fn) {
      // Loop through event store and remove the passed function.
      for (i=0; i<events.length; i++) {
        if (fn === events[i].fn) {
          events.splice(i, 1);
          break;
        }
      }
    } else if (event) {
      // Clear out all events of this type.
      this['_on' + event] = [];
    } else {
      // Clear out all events of every type.
      var keys = Object.keys(this);
      for (i=0; i<keys.length; i++) {
        if ((keys[i].indexOf('_on') === 0) && Array.isArray(this[keys[i]])) {
          this[keys[i]] = [];
        }
      }
    }
    return this;
  }
  once(event, fn) {
    // Setup the event listener.
    this.on(event, fn, 1);
    return this;
  }
  _emit(event, msg) {
    var events = this['_on' + event];
    // Loop through event store and fire all functions.
    for (var i=events.length-1; i>=0; i--) {
      events[i].fn(msg);
      if (events[i].once) {
        this.off(event, events[i].fn);
      }
    }
    return this;
  }
}
class Mixer {
  constructor(options) {
    this.audioContext=null;
    this.masterMute=null;
    this.master=null;
    this.sons=options.sons || [];
    this.players=[];
    this.unlocked=false;
    this.onUnlock=options.unlock || (()=>{});
    this.context=null;
    this.trackVolume={};
    this.inited=false;
  }
  init(){
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.audioContext.onstatechange=()=>{
      if (this.audioContext.state!=='closed') {
        console.log('state change !!!');
        this.unlock();
      }
    };
    this.masterMute = this.audioContext.createGain();
    this.masterMute.connect(this.audioContext.destination);
    this.masterMute.gain.setValueAtTime(1, this.audioContext.currentTime);
    this.master = this.audioContext.createGain();
    this.master.connect(this.masterMute);
    this.master.gain.setValueAtTime(1, this.audioContext.currentTime);
    [...Array(20).keys()].forEach((trackIdx) => {
      const g=this.audioContext.createGain();
      g.connect(this.master);
      g.gain.setValueAtTime(1, this.audioContext.currentTime);
      this.trackVolume['track-'+trackIdx]=g;
    });
    const { audioContext,master } = this;
    this.sons.forEach((son) => {
      son.listeners.forEach((listener) => {
        const p=this.players.find((o)=>o.src===son.url && o.track===listener.track);
        if (!p) {
          const { track } =  listener;
          const player=new Player({src:son.url,audioContext,master,track,trackVolume:this.trackVolume[track],volume:0.2});
          this.players.push(player);
          console.log('added',son.url,player);
        }
      });
    });
    this.inited=true;
  }
  unlock(){
    if (this.audioContext.state!=='running') {
      console.log('need unlock');
      this.onUnlock();
    } else if (!this.unlocked) {
      this.unlocked=true;
      console.log('audio context running, all good !');
    }
  }
  getPlayer(url){
    return this.players.find((o)=>o.src===url);
  }
  setContext(context){
    this.context=context;
  }
  play(url){
    const player=this.getPlayer(url);
    console.log(player);
    if (player) player.play();
  }
  playOut(url){
    const player=this.getPlayer(url);
    if (player) player.playOut();
  }
  pause(url){
    const player=this.getPlayer(url);
    if (player) player.pause();
  }
  fadeOut(url){
    const player=this.getPlayer(url);
    if (player) player.fade(0,500);
  }
  fadeIn(url){
    const player=this.getPlayer(url);
    if (player) player.fade(1,500);
  }
  stop(url){
    const player=this.getPlayer(url);
    if (player) player.stop();
  }
}
export default Mixer;
